import { capitalize, trim } from "lodash";
import config from "./config";
import STRING_CONSTANTS from "./shared/constants/StringConstants";
import { DefaultFavIcon } from "./components/DynamicForm/constants";

const { ruleServerUrl } = config;
const getAuthToken = () => {
  const params = new URLSearchParams(window.location.search);
  if (params.has(STRING_CONSTANTS.TOKEN_PARAM_KEY))
    return params.get(STRING_CONSTANTS.TOKEN_PARAM_KEY);

  return document.cookie.split(";").reduce((acc, val) => {
    const [key, value] = val.split("=");

    if (trim(key) === STRING_CONSTANTS.COOKIE_NAME) acc = value;
    return acc;
  }, null);
};

export const initialize = () => {
  const authToken = getAuthToken();

  if (!authToken) return false;

  const manifestElement = document.createElement("link");
  manifestElement.setAttribute("rel", "manifest");
  manifestElement.setAttribute(
    "href",
    `${ruleServerUrl}/pwa/manifest.json?token=${authToken}`
  );
  document.head.appendChild(manifestElement);
  return true;
};

export const handleDocumentChanges = ({ tenant, logo }) => {
  document.title = capitalize(tenant || "casa")
  const manifestElement = document.createElement("link")
  const appleIcon = document.getElementById("apple-touch-icon");
  appleIcon.setAttribute("href", logo || DefaultFavIcon)
  manifestElement.setAttribute("rel", "icon")
  manifestElement.setAttribute("href", logo || DefaultFavIcon)
  return document.head.appendChild(manifestElement)
}